import React from "react";
import "./contact.css";
import ACman from "../..//components/pictures/ACman.jpg";
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';


export class Contact extends React.Component {
    constructor(props) {
        super (props);
    
        this.sendMail = this.sendMail.bind(this);
        
        this.state = {

        };    
      }
    
      async componentDidMount() {  

      }
    
      async sendMail() {
        const fullname = document.getElementById('fullname').value;
        const message = document.getElementById('message').value;
        const email = document.getElementById('email').value;
        
        const requestOptions = {
          method: 'POST',
          body: JSON.stringify({ 
            fullname: fullname,
            message: message,
            email: email
        })};
  
        // await fetch('https://climacosta.com/sendMail.php', requestOptions)
        //   .then(response => response.json())
        //   .then(data => {});
        
        toast('Thank you for your message! We will get back to you as soon as possible!'); 
      };

    render() {
        return(
            <div>
            <div className="ACpicture container AC6"></div>      
                <div>
                    <h1 id="contact" className="headline">Get In Touch With Us</h1>
                    <h4 className="text">For any questions do not hesitate to contact us.</h4>
                </div>
                <Form className="message">
                    <FormGroup>
                        <Label className="info">Message</Label>
                        <Input className="textArea" type="textarea" name="message" id="message"/>
                    </FormGroup> 
                </Form>
                <Form className="row">
                     <FormGroup className="col-md-5 info">
                        <Label>Fullname</Label>
                        <Input className="textArea" type="fullname" name="fullname" id="fullname"/>
                    </FormGroup>                     
                    <FormGroup className="col-md-5 info">
                        <Label>Email</Label>
                        <Input className="textArea" type="email" name="email" id="email"/>
                    </FormGroup>
                    <FormGroup className="col-md-2">
                        <Button className="btn" onClick={()=>this.sendMail()}>Send</Button>
                    </FormGroup>                        
                </Form>                                   
                
            </div>                         
                    
        )
    }
}

export default Contact;      




