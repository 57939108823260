import './App.css';
import Specialist from "../src/components/specialist/specialist";
import Story from "../src/components/story/story";
import Brands from "../src/components/brands/brands";
import Testimonials from "../src/components/testimonials/testimonials";
import Contact from "../src/components/contact/contact";
import Footer from "../src/components/footer/footer";
import React from 'react';
import NavBar from "../src/components/navBar/navBar";
import Services from "../src/components/services/services";
import Carousel from "../src/components/carousel/carousel";
import NavBar1 from "../src/components/navBar1/navBar1";

export class App extends React.Component {
  render() {  
    return (
      <div>
        <div className="container">
          <NavBar1/>
          {/* <NavBar/> */}
          <Specialist/>   
          <Story/>    
          <Services/>    
          {/* <Brands/>   */}
          {/* <Testimonials/>    */}
          {/* <Carousel/> */}
          <Contact/> 
          <Footer/>
        </div>
      </div>
    );
  }
}

export default App;
