import React from 'react';
import "./services.css";
import ACvit from "../..//components/pictures/ACvit.jpg";
import onWall from "../pictures/onWall.jpg";
import { Card, CardTitle, CardText, Row, Col } from 'reactstrap';
import icon from "../pictures/icon.png";
import hands from "../pictures/hands.png";
import skiftnyckel from "../pictures/skiftnyckel.png";
import hjul from "../pictures/hjul.png";


export class Services extends React.Component {
  render() {  
    return (
      <div>
          <div className="ACpicture container AC3">
           </div>
          <div>
            <h1 id="services" className="headline">Our Services</h1>
            <h4 className="text">Each installation, repair or maintenance is carried out by own labor, with recognized experience and responsibility.
            The supply of materials, construction management and logistics always start from company personnel, which is implied by full customer satisfaction.</h4>
          </div>

                <div className="row">
                <div className="col-md-4">
                  <div className="col-md-1 deskptopItem"><img className="icon2" src={hands}/></div>
                    <Card className="cardbody">
                        <CardTitle className="cardtitle">Personalized service</CardTitle>
                        <CardText className="cardtext">Each client is unique and requires a personalized service to adjust their air conditioning installation in  
                        to the office, building, leisure or sports center in question. Climacosta will develop a comprehensive air conditioning installation 
                        service, which can cover the study, its subsequent project, the installation, carried out by our own technicians and personnel from 
                        different guilds (plumbing, electricity …) in addition to legalization, maintenance and repair, if necessary.</CardText>
                    </Card>
                  </div>

                <div className="col-md-4">
                  <div className="col-md-1 deskptopItem"><img className="icon2" src={skiftnyckel}/></div>
                    <Card className="cardbody">
                        <CardTitle className="cardtitle">Installation</CardTitle>
                        <CardText className="cardtext">We are registered in Industry, as Level 2 of authorized installation company, in air conditioning equipment, air 
                        conditioning systems, projects, legalizations, new low-flammable refrigerant gases … In Climacosta we have the appropriate approval for the installation
                        of extraction systems and ducts in garages, according to the new <a href="https://www.boe.es/eli/es/rd/2017/05/22/513">RIPCI Regulation.</a></CardText>
                    </Card>
                </div>

                <div className="col-md-4">
                  <div className="col-md-1 deskptopItem"><img className="icon" src={hjul}/></div>
                    <Card className="cardbody">
                        <CardTitle className="cardtitle">Maintenance</CardTitle>
                        <CardText className="cardtext">You can hire with Climate Costa the air conditioning installation service in Madrid, associating a support of 
                        preventive, corrective and conductive maintenance. We are an installation, repair and maintenance company that operates anywhere on the Costa del Sol.</CardText>
                    </Card>
                  </div>
                </div>
            </div>
        
    );
  }
}

export default Services;
