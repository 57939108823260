import React from "react";
import "./story.css";
import ACblackbackground from "../pictures/ACblackbackground.png";
import ACthermostat from "../pictures/ACthermostat.png"

export class Story extends React.Component {
    render() {
        return (
            <div>
                <div className="ACpicture container AC2">
                </div>
                <div>
                    <h1 id="story" className="headline">Our Story</h1>
                    <h4 className="text">With a combined 20 years experience working in all sectors of aircondition and ventilation.Fully english
                    and spanish spoken, always working with the best materials to provide you the best service.Our commitment to the well-being of our customers makes us 
                    work with the best brands in the Spanish market, rejecting, whenever we can, the installation of low quality units, trying to avoid problems for both you 
                    and us since, after each installation, We are involved in the maintenance of your machines throughout their useful life.</h4>
                </div>
            </div>



        )
    }
}

export default Story;