import React from "react";
import "./testimonials.css";
import ACman from "../..//components/pictures/ACman.jpg";
import Carousel from "../carousel/carousel";


export class Testimonials extends React.Component {
    render() {
        return(
            <div>
                <div className="ACpicture container AC4">
            </div>
            <div> 
                <h1 id="testimonials" className="headline">Testimonials</h1></div>
                <h4 className="text">These are some testimonials from some happy clients of ours.</h4>
            <div>
                    {/* <Carousel/> */}
                </div>
</div>
       
        )
    }
}

export default Testimonials;   
           



