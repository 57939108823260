import React from "react";
import "./specialist.css";
import ACman_NY from "../..//components/pictures/ACman.jpg";
import { Card, CardTitle, CardText, Row, Col } from 'reactstrap';
import installation from "../pictures/installation.jpg";
import icon from "../pictures/icon.png";
import stopur from "../pictures/stopur.png";
import ok from "../pictures/ok.png";
import service from "../pictures/service.png";
import klocka from "../pictures/klocka.png";


export class Specialist extends React.Component {
    render() {
        return (
            <div>
                <div className="ACpicture container AC1">
                </div>
                
                <div>
                    <h1 className="headline">Your Climate Specialist On The Coast</h1>
                    <h4 className="text">Climacosta solutions S.L. is a company that specialises in air conditioning and ventilation. We are based in Mijas Costa/Fuengirola 
                    area, but we reach all areas of the Costa del Sol.</h4>
                </div>
                
                <div className="row">
                    <div className="col-md-4">
                        <div className="col-md-1 deskptopItem"><img className="icon" src={ok}/></div>
                        <div className="col-md-11">
                            <Card className="cardbody">
                            <CardTitle className="cardtitle">Climate Specialist</CardTitle>
                            <CardText className="cardtext">Behind Climacosta there is an air conditioning company in Costa del Sol that is committed to innovation, safety and 
                            quality. Our differential value lies in the trust and satisfaction of customers who rely on our timely installation service or the maintenance of 
                            their facilities.</CardText>
                            </Card>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="col-md-1 deskptopItem"><img className="icon2" src={service}/></div>
                        <div className="col-md-11">
                            <Card className="cardbody">
                            <CardTitle className="cardtitle">Our services</CardTitle>
                            <CardText className="cardtext">Each installation, repair or maintenance is carried out by own labor...</CardText>
                            </Card>
                        </div>
                    </div>

                    <div className="col-md-4">           
                        <div className="col-md-1 deskptopItem"><img className="icon2" src={klocka}/></div>
                        <div className="col-md-11">
                            <Card className="cardbody">
                                <CardTitle className="cardtitle">Office hours</CardTitle>
                                <CardText className="cardtext">Monday-Friday 09.00-17.00  <br></br>
                                (+34) 662 167 395 | (+34) 662 167 396 <br></br>
                                <a className="email" href="mailto:info@climacosta.com">info@climacosta.com</a></CardText>    
                            </Card> 
                        </div>    
                    </div>    
                </div>
            </div>

        )  
    }
}

export default Specialist;