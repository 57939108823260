import react from "react";
import React from "react";
import "./navBar1.css";
import logo from "../..//components/pictures/logo.png";

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
  } from 'reactstrap';



export class NavBar1 extends react.Component {
    constructor(props) {
            super(props);
        
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            isOpen: false,
            collapsed: true,
        };
        };
    
    toggleNavbar () {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };
    
    render() {
        return (
            <div className="navbar-wrapper">
                <Navbar light expand="md">
                    <NavbarBrand href="/"><img className="logo" src={logo}/></NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ms-auto" navbar>
                        <NavItem>
                             <NavLink href="#story">Our story</NavLink>
                        </NavItem>
                        <NavItem>
                                <NavLink href="#services">Our services</NavLink>
                        </NavItem>
                        {/* <NavItem>
                             <NavLink href="#brands">Products & Brands</NavLink>
                        </NavItem>
                        <NavItem>
                             <NavLink href="#testimonials">Testimonials</NavLink>
                        </NavItem> */}
                        <NavItem>
                                <NavLink href="#contact">Contact us</NavLink>
                        </NavItem>           
                    </Nav>
                    </Collapse>
                </Navbar>
         </div>                                   


        )

        }
    }
export default NavBar1;





