import React from 'react';
import "./brands.css";
import ACman from "../..//components/pictures/ACman.jpg";
import mitsubishi from "../pictures/mitsubishi.svg";

export class Brands extends React.Component {
    render() {  
      return (
        <div>
            <div className="ACpicture container AC5">
            </div> 
            <h1 id="brands" className="headline">Products and Brands We Work With</h1>
                <h4 className="text">We work with direct infused AC, Indirect infused AC, Hotel level AC, Garage level ventination ducted systems among others.
                We also carefully source reliable brands that we know allows us to provide you with great services and parts for your
                installations. Our experience also includes a wide selection of other brands should you need our help with an existing
                installation.</h4>
            <h3 className="headline">Some of the brands we work with</h3>
            <div className="row">
              <div className="col-md-4 brand">
               <img className="brandImg" alt="Mitsubishi" title="Mitsubishi" src={mitsubishi}/>
            </div>
            <div className="col-md-4 brand">
               <img className="brandImg" alt="Mitsubishi" title="Mitsubishi" src={mitsubishi}/>
            </div>
            <div className="col-md-4 brand">
               <img className="brandImg" alt="Mitsubishi" title="Mitsubishi" src={mitsubishi}/>
            </div></div>

        </div>
          
      );
    }
  }
  
  export default Brands;