import React from "react";
import "./footer.css";
import {Collapse,Navbar, NavbarToggler, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText, NavbarBrand} from 'reactstrap';
import fb_NY from "../pictures/fb_NY.png";
import instagram from "../pictures/instagram.png";

export class Footer extends React.Component {
    render() {
        return (
            <div>              
                <div className="foot">                    
                    <div className="row">
                        <div className="col-md-6">
                            <a className="col-md-2" href="#story">Our story &nbsp;| &nbsp; </a>
                            <a className="col-md-2" href="#services">Our services | Phone: (+34) 662 167 395 | (+34) 662 167 396</a>
                            {/* <a className="col-md-2" href="#brands">Products & Brands</a>
                            <a className="col-md-2" href="#testimonials">Testimonials</a> */}
                            {/* <a className="col-md-2" href="#contact">Contact us</a> */}
                        </div>

                        <div className="col-md-6 footerIcon">
                            <a href="https://www.facebook.com/climacostasolutions"><img className="facebook" alt="Facebook" src={fb_NY}/></a>
                            <a href="https://www.instagram.com/climacosta_solutions_sl/"><img className="insta" alt="Instagram" src={instagram}/></a></div>
                        </div>
                </div>                
            </div>            





        )
    }
}

export default Footer;